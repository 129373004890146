<template>
	<div class="bg-blue-gray-50">
		<div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
			<div class="flex justify-center">
				<img class="w-40" :src="require('@/assets/core/logo-sayls.png')" alt="Chiesi Sayl´s" />
			</div>

			<div class="text-center mt-10" v-if="state.user">
				<h2 class="text-base font-semibold text-accent tracking-wide uppercase">¡HOLA {{state.user.name}}!</h2>
				<p class="max-w-2xl mt-2 mx-auto text-gray-600 sm:tracking-tight">
					Bienvenido(a) a esta plataforma, donde serás un(a) gran navegante y podrás poner a prueba tus conocimientos sobre <span class="font-semibold">ASMA</span> y <span class="font-semibold">EPOC</span>, compartir un caso clínico y continuar con tu preparación como profesional de la salud.
				</p>
			</div>

			<div class="mt-8">
				<div class="flex justify-center cursor-pointer pb-8 max-w-4xl mx-auto" @click="go('/play')">				
					<img class="rounded-3xl shadow-xl border-4 border-black" :src="require('@/assets/core/general-with.jpg')" />
				</div>
			</div>

			<div class="relative bg-blue-gray-50 mt-4 pb-10">
				<div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-40 lg:max-w-6xl">
					<div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
						<div v-for="feature in state.features" :key="feature.name" class="pt-6">
							<div class="flow-root bg-white border-4 border-blue-gray-200 rounded-lg px-8 pb-8 cursor-pointer hover:shadow-xl" @click="go(feature.to)">
								<div class="-mt-6">
									<div>
										<span class="inline-flex items-center justify-center p-3 bg-secondary rounded-md shadow-lg">
											<component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
										</span>
									</div>
									<h3 class="mt-8 text-xl font-medium text-accent tracking-tight">{{ feature.name }}</h3>
									<p class="mt-5 text-base text-gray-600">
										{{feature.text}}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="mt-6">
				<a href="https://chiesimexico.com" target="_blank">
					<img class="shadow-md border-2 border-blue-gray-200 rounded-2xl hover:shadow-lg" :src="require('@/assets/home/banner-chiesi.png')" alt="">
				</a>
			</div>

			<div class="flex justify-center mt-12 mb-20">
				<div class="max-w-sm text-center">
					<div class="font-semibold text-primary text-lg">Contacto</div>
					<div class="text-sm">
						Si tienes alguna pregunta puedes contactarte con:
					</div>
					<div class="mt-4 text-center">
						<div class="font-medium">
							Lorena Pizaña
						</div>
						<div class="text-sm mt-1">
							<MailIcon class="w-4 h-4 text-primary inline-block mr-1" />
							l.pizana@chiesi.com
						</div>
						<div class="text-sm mt-1">
							<PhoneIcon class="w-4 h-4 text-primary inline-block mr-1" />
							551 353 06 30
						</div>
					</div>
				</div>
			</div>

			<FooterIntern />
		</div>
	</div>
</template>

<script>
import { computed, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LightningBoltIcon, AdjustmentsIcon, DocumentTextIcon, MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import FooterIntern from '@/components/core/FooterIntern'

export default {
	components: {
		LightningBoltIcon,
		FooterIntern,
		MailIcon,
		PhoneIcon
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const user = computed(() => store.getters.user);

		const state = reactive({
			user: user,
			features: [
				{ name: 'Caso clínico', to:'/case', icon: DocumentTextIcon, text:'Comparte un caso clínico de alguno de tus pacientes.' },
				{ name: 'Mis puntos', to:'/points', icon: AdjustmentsIcon, text: 'Revisa tu puntuación y la de los demás navegantes en el Top 20.' }
			],
		});

		function go(dir) {
			router.push(dir);
		}

		return {
			state,
			go
		}
	}
}
</script>